import React, { useState, useEffect } from "react";
import "./ProgramSelectionPage.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function ProgramSelectionPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const formData = location.state;

  // Set initial state to 'live' or 'curated' based on your requirement
  const [selectedProgram, setSelectedProgram] = useState("live");

  useEffect(() => {
    // Fetch initial selected program from location state if available
    if (formData && formData.selectedProgram) {
      setSelectedProgram(formData.selectedProgram);
    }
  }, [formData]);

  const handleSelection = (programType) => {
    setSelectedProgram(programType);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dobString = `${formData.dateOfBirth.day}/${formData.dateOfBirth.month}/${formData.dateOfBirth.year}`;
      const postData = {
        ...formData,
        dateOfBirth: dobString,
        program_type: selectedProgram,
      };

      // Call the API with the selected program type and basic info data
      await axios.post("https://api.epicore.fit/google/store_data", postData);

      // Navigate based on the selected program
      if (selectedProgram === "live") {
        navigate("/google-form");
      } else if (selectedProgram === "curated") {
        navigate("/google-form-curated");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <div className="program-selection-container">
      <h1>Please select a program of your choice.</h1>
      <div className="program-box-container">
        <div
          className={`program-box ${
            selectedProgram === "live" ? "selected" : ""
          }`}
          onClick={() => handleSelection("live")}
        >
          <img className="program-image" src="/live.png" alt="Live Classes" />
          <div style={{ padding: "5px" }}>
            <p className="program-description">Live Classes</p>
            <p className="program-info">Interactive live class platform</p>
          </div>
        </div>
        <div
          className={`program-box ${
            selectedProgram === "curated" ? "selected" : ""
          }`}
          onClick={() => handleSelection("curated")}
        >
          <img
            className="program-image"
            src="/curated.png"
            alt="Curated Programs"
          />
          <div style={{ padding: "5px" }}>
            <p className="program-description">Curated Programs</p>
            <p className="program-info">Tailored fitness plan by experts</p>
          </div>
        </div>
      </div>
      <button className="submit-btn" type="submit" onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
}

export default ProgramSelectionPage;
