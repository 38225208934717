import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BasicInfoForm from './BasicInfoForm';
import ProgramSelectionPage from './ProgramSelectionPage';
import GoogleFormPage from './GoogleFormPage';
import GoogleFormPageCurated from './GoogleFormPageCurated';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<BasicInfoForm />} />
        <Route path="/program-selection" element={<ProgramSelectionPage />} />
        <Route path="/google-form" element={<GoogleFormPage />} />
        <Route path="/google-form-curated" element={<GoogleFormPageCurated />} />
      </Routes>
    </Router>
  );
}

export default App;
