import React, { useState } from "react";
import axios from "axios";
import "./BasicInfoForm.css"; // Import CSS file for styling
import { useNavigate } from "react-router-dom";
import logo from "./logo.png"; // Import your logo image

function BasicInfoForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    city: "",
    dateOfBirth: {
      day: "",
      month: "",
      year: "",
    },
    height: "",
    weight: "",
    gender: "",
    address: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    mobileNumber: false,
    city: false,
    day: false,
    month: false,
    year: false,
    height: false,
    weight: false,
    gender: false,
    address: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Check for mandatory fields
    if (name !== "lastName" && !value.trim()) {
      setFormErrors({ ...formErrors, [name]: true });
    } else {
      setFormErrors({ ...formErrors, [name]: false });
    }

    // Additional validations
    if (name === "mobileNumber") {
      const isValidMobileNumber = /^\d{0,10}$/.test(value);
      setFormErrors({ ...formErrors, mobileNumber: !isValidMobileNumber });
    }
    if (name === "height" || name === "weight") {
      const isValidNumber = /^\d+$/.test(value);
      setFormErrors({ ...formErrors, [name]: !isValidNumber });
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...formData,
      dateOfBirth: {
        ...prevState.dateOfBirth,
        [name]: value,
      },
    }));

    // Check for mandatory fields
    if (!value) {
      setFormErrors({ ...formErrors, [name]: true });
    } else {
      setFormErrors({ ...formErrors, [name]: false });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Check if any mandatory fields are empty
      const mandatoryFields = [
        "firstName",
        "mobileNumber",
        "city",
        "height",
        "weight",
        "gender",
        "address",
      ];
      const hasEmptyFields = mandatoryFields.some((field) => !formData[field]);

      if (hasEmptyFields) {
        // Display error messages for empty mandatory fields
        setFormErrors((prevErrors) => {
          const newErrors = {};
          mandatoryFields.forEach((field) => {
            if (!formData[field]) {
              newErrors[field] = true;
            }
          });
          return { ...prevErrors, ...newErrors };
        });
      } else if (Object.values(formErrors).some((error) => error)) {
        // Display error messages if any field has an error
        console.error("Please fix the errors in the form.");
      } else {
        // All mandatory fields are filled, navigate to the next page
        navigate("/program-selection", { state: formData });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <div className="container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="logo-text">EPICORE</h1>
      </div>
      <div className="form-container">
        <h2
          style={{
            textAlign: "center",
            fontFamily: "Montserrat, sans-serif",
            color: "#273d07",
          }}
        >
          Basic Information Form
        </h2>
        <form onSubmit={handleSubmit} className="form">
          <div className="input-group-name">
            <input
              type="text"
              name="firstName"
              placeholder="First Name *"
              value={formData.firstName}
              onChange={handleChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="input-group"></div>
          {formErrors.firstName && (
            <p className="error-message">First Name is required</p>
          )}
          <div className="input-group">
            <input
              type="text"
              name="mobileNumber"
              placeholder="Mobile Number *"
              value={formData.mobileNumber}
              onChange={handleChange}
            />
            {formErrors.mobileNumber && (
              <p className="error-message">Invalid Mobile Number</p>
            )}
          </div>
          <div className="input-group">
            <input
              type="text"
              name="city"
              placeholder="City *"
              value={formData.city}
              onChange={handleChange}
            />
            {formErrors.city && (
              <p className="error-message">City is required</p>
            )}
          </div>
          <div className="input-group dob-group">
            <p>Enter D.O.B : &nbsp;</p>
            <select
              name="day"
              value={formData.dateOfBirth.day}
              onChange={handleDateChange}
            >
              <option value="">Day</option>
              {[...Array(31)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
            {formErrors.day && <p className="error-message">Day is required</p>}
            <select
              name="month"
              value={formData.dateOfBirth.month}
              onChange={handleDateChange}
            >
              <option value="">Month</option>
              {[...Array(12)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
            {formErrors.month && (
              <p className="error-message">Month is required</p>
            )}
            <select
              name="year"
              value={formData.dateOfBirth.year}
              onChange={handleDateChange}
            >
              <option value="">Year</option>
              {[...Array(125)].map((_, index) => (
                <option key={2024 - index} value={2024 - index}>
                  {2024 - index}
                </option>
              ))}
            </select>
            {formErrors.year && (
              <p className="error-message">Year is required</p>
            )}
          </div>
          <div className="input-group">
            <input
              type="text"
              name="height"
              placeholder="Height (cm) *"
              value={formData.height}
              onChange={handleChange}
            />
            {formErrors.height && (
              <p className="error-message">Height should contain only digits</p>
            )}
          </div>
          <div className="input-group">
            <input
              type="text"
              name="weight"
              placeholder="Weight (kg) *"
              value={formData.weight}
              onChange={handleChange}
            />
            {formErrors.weight && (
              <p className="error-message">Weight should contain only digits</p>
            )}
          </div>
          <div className="input-group">
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            {formErrors.gender && (
              <p className="error-message">Gender is required</p>
            )}
          </div>
          <div className="input-group">
            <input
              type="text"
              name="address"
              placeholder="Billing Address *"
              value={formData.address}
              onChange={handleChange}
            />
            {formErrors.address && (
              <p className="error-message">Address is required</p>
            )}
          </div>
          <div style={{ alignItems: "center" }}>
            <button
              type="submit"
              className="submit-btn"
              disabled={Object.values(formErrors).some((error) => error)}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BasicInfoForm;
