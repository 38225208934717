// GoogleFormPage.js
import React, { useEffect, useRef } from "react";
import "./GoogleFormPage.css";
import { useNavigate } from "react-router-dom";

function GoogleFormPage() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    console.log("Redirecting to /program-selection");
    navigate("/program-selection");
  };

  return (
    <div className="google-form-container">
      <iframe
        className="google-form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSc2Tu-Ur4fViZAfjavmiQlMYXqZ0TAVJhuKG-AwmP3NIu7vJA/viewform?usp=sharing"
        width="640"
        height="2284"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Google Form"
      >
        Loading…
      </iframe>
    </div>
  );
}

export default GoogleFormPage;
